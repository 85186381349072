import React from 'react';
import Navbar from './Navbar';
import Artifacts from './Artifacts'
import '../App.css';

function About() {
  return (
    <div className='about-div'>
      <Artifacts/>
      <Navbar />
      <div className='me-div'>
        <img alt='headshot' className='me' src={require('../images/headshot.jpg')}/>
      </div>
      <p className='about-text'>I am a Full Stack Developer who discovered a passion for programming while getting a degree in Mechanical Engineering. I specialize in React/Node applications but I am always branching out and learning new languages and technologies such as Python, Django, and GraphQL.</p>
      <img alt='carousel shadow' className='shadow' src={require('../images/Shadow-PNG-Pic3.png')}/>
    </div>
  );
}

export default About;