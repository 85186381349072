import React from 'react';
import Carousel from 'react-spring-3d-carousel';
import Navbar from './Navbar';
import Artifacts from './Artifacts'
import Project from './Project'
import '../App.css';

function Projects() {

  const qh = {
    title: "Quality Hub",
    image: require("../images/qh.JPG"),
    text: "Quality hub is a full stack web application that serves as marketplace for users looking to practice their interview skills through video chat.",
    github: "https://github.com/TheCovey",
    link: "https://explorequality.com",
    stack: [require("../images/reactb.png"),require("../images/apollob.png"),require("../images/graphqlb.png"),require("../images/webrtcb.png")]
  }

  const cyoa = {
    title: "Create Your Own Adventure",
    image: require("../images/cyoa.JPG"),
    text: "Create Your Own Adventure is a story telling tool that provides story tellers an intuative visual interface to make complex multi-outcome stories.",
    github: "https://github.com/Lambda-Hackathon-2020-Git-Adventure",
    link: "https://createadventure.netlify.com/",
    stack: [require("../images/reactb.png"),require("../images/nodeb.png"),require("../images/expressb.png")]
  }

  const cteb = {
    title: "Chrono Trigger 2: Electric Boogaloo",
    image: require("../images/cteb2.JPG"),
    text: "Chrono Trigger 2: Electric Boogaloo is an exploration game that was created for the purpose of learning how to use a Python/Django back end.",
    github: "https://github.com/Chrono-Trigger-2-Electric-Boogaloo",
    link: "https://front-end-coral.now.sh/",
    stack: [require("../images/reactb.png"),require("../images/pythonb.png"),require("../images/djangob.png")]
  }

  let slides = [
    {
      key: 1,
      content: <Project title={qh.title} image={qh.image} text={qh.text} stack={qh.stack} github={qh.github} link={qh.link}/>
    },
    {
      key: 2,
      content: <Project title={cyoa.title} image={cyoa.image} text={cyoa.text} stack={cyoa.stack} github={cyoa.github} link={cyoa.link}/>
    },
    {
      key: 3,
      content: <Project title={cteb.title} image={cteb.image} text={cteb.text} stack={cteb.stack} github={cteb.github} link={cteb.link}/>
    },
  ];
  return (
    <div className='projects'>
        <Artifacts/>
        <Navbar />
        <div className='carousel'>
            <Carousel slides={slides} showNavigation={true} offsetRadius={5}/>
        </div>
        <img alt='img shadow' className='shadow' src={require('../images/Shadow-PNG-Pic3.png')}/>
    </div>
  );
}

export default Projects;