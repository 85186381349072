import React from 'react';
import { Link } from 'react-router-dom';
import Footer from './Footer'
import Artifacts from './Artifacts'

import pdf from '../resume/ResumeFSWD.pdf'

import '../App.css';

function Home() {
  return (
    <div className='home'>
      <Artifacts/>
        <div className='homeLeft'>
            <img alt='golden apple' className='LRimages' src={require("../images/leftIMG.jpg")}/>
            <h1 className="firstName">RYAN</h1>
            <h2 className="fsd">FULL   STACK   DEVELOPER</h2>
        </div>
        <div className='homeRight'>
            <h1 className="lastName">ZIEGENFUS</h1>
            <nav className='home-nav'>
              <Link className='home-nav-item' to='/about'>ABOUT</Link>
              <a className='home-nav-item' href={pdf} target='_blank' rel="noopener noreferrer">RESUME</a>
              <Link className='home-nav-item' to='/projects'>PROJECTS</Link>
              <Link className='home-nav-item' to='/contact'>CONTACT ME</Link>
            </nav>
            <img alt='golden crown' className='LRimages' src={require("../images/rightIMG.jpg")}/>
        </div>
        <Footer className='footer'/>
    </div>
  );
}

export default Home;
